@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}



#left {
  position: absolute;
  top: 0;
  left: 0;
  width: 101%; /* If you make it 100%, you get a bit of black showing along the diagonal */
  height: 100%;
  background: #E94B60;
  -webkit-clip-path: polygon(0 0, 76% 0, 24% 100%, 0% 100%);
  clip-path: polygon(0 0, 60% 0, 40% 100%, 0% 100%);
}

#right {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #3f475f;
  -webkit-clip-path: polygon(76% 0, 100% 0, 100% 100%, 24% 100%);
  clip-path: polygon(40% 0, 100% 0, 100% 100%, 30% 100%);
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.metr1::-webkit-meter-optimum-value {
  background: #1BC5BD;
  border:0cm;
  outline: none;
}
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
.metr1::-moz-meter-bar { /* Firefox Pseudo Class */
  background: #1BC5BD;
  border:0cm;
  outline: none;
}

.metr2::-webkit-meter-optimum-value {
  background: #E94B60;
  border:0cm;
  outline: none;
}
.metr2::-moz-meter-bar { /* Firefox Pseudo Class */
  background: #E94B60;
  border:0cm;
  outline: none;
}

.metr3::-webkit-meter-optimum-value {
  background: #FE8C4C;
  border:0cm;
  outline: none;
}
.metr3::-moz-meter-bar { /* Firefox Pseudo Class */
  background: #FE8C4C;
  border:0cm;
  outline: none;
}

.metr4::-webkit-meter-optimum-value {
  background: #727CF5;
  border:0cm;
  outline: none;
}
.metr4::-moz-meter-bar { /* Firefox Pseudo Class */
  background: #727CF5;
  border:0cm;
  outline: none;
}

.metr5::-webkit-meter-optimum-value {
  background: #42AAFF;
  border:0cm;
  outline: none;
}
.metr5::-moz-meter-bar { /* Firefox Pseudo Class */
  background: #42AAFF;
  border:0cm;
  outline: none;
}
.metr6::-webkit-meter-optimum-value {
  background: #FECC4C;
  border:0cm;
  outline: none;
}
.metr6::-moz-meter-bar { /* Firefox Pseudo Class */
  background: #FECC4C;
  border:0cm;
  outline: none;
}

.radial-gradient {

  background-repeat:no-repeat;

background-size: 100% 100%;
background-image: radial-gradient(farthest-corner at 40px 40px,
    #111 20%, #333 100%,#ddd 100%);
}

@media screen and (max-width: 768px){

  #form-file-upload {
    height: 8rem;
    width: 8rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    border-radius: 100%;
    margin-left:auto;
    margin-right: auto;
    
  }
}

@media screen and (min-width: 768px){

  #form-file-upload {
    height: 11rem;
    width: 11rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px){

  #form-file-upload-4 {
    height: 10rem;
    width: 10rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    border-radius: 100%;
    margin-left:auto;
    margin-right: auto;
    
  }
}

@media screen and (min-width: 768px){

  #form-file-upload-4 {
    height: 10rem;
    width: 10rem;
    max-width: 100%;
    text-align: center;
    position: relative;
    border-radius: 0;
    margin-left: auto;
    margin-right: auto;
  }
}




#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 100%;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload-4 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

pre {
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
}

.image_card:hover{
  transform: rotateY(90deg) ;
  transition: 0.6s;
  transform-style: preserve-3d;
}